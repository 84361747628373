import React from 'react';

import { Navbar, Nav, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import LanguageSwitcher from './LanguageSwitcher';
const Navigation = () => {

    const { t } = useTranslation();
    const { lang } = useParams(); // Get the current language parameter
    const navigate = useNavigate();
    const location = useLocation(); // Get the current path
    const { i18n } = useTranslation();
    const currentLang = i18n.language;  // Get current language



    return (
        <Navbar sticky="top" className="bg-light text-dark" expand="lg" variant="light">
            <Container fluid>
                <Navbar.Brand as={Link} to={`/${lang || 'en'}`} className="d-flex align-items-center">
                    <img src="/android-chrome-192x192.png" width="50" height="50" className="d-inline-block align-top mr-1" alt="" />
                    <span>
                        {t('global.app_name')}
                        <small className='d-none d-sm-block' style={{ fontSize: 12 }}> {t('global.app_slogan')}</small>
                    </span>
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />

                <Navbar.Collapse id="basic-navbar-nav ">
                    <Nav className="mr-auto">
                        <Nav.Link as={Link} to={`/${lang || 'en'}`}> {t('global.nav_home')}</Nav.Link>
                        <Nav.Link as={Link} to={`/${lang || 'en'}/findpets`}> {t('global.nav_find')}</Nav.Link>
                        <Nav.Link as={Link} to={`/${lang || 'en'}/breed-detector`}> {t('global.nav_breed')}</Nav.Link>
                        <Nav.Link as={Link} to={`/${lang || 'en'}/pet-advisor`}> {t('global.nav_advisor')}</Nav.Link>
                        <Nav.Link as={Link} to={`/${lang || 'en'}/videos`}> {t('global.nav_video')}</Nav.Link>
                        <Nav.Link as={Link} to={`/${lang || 'en'}/about-us`}> {t('aboutUs.title')} </Nav.Link>
                        <Nav.Link href="https://store.petshome.app" target="_blank" rel="noopener">
                            {t('Pet Store')}
                        </Nav.Link>


                        <LanguageSwitcher />
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Navigation;
