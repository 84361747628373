import React, { useEffect } from 'react';
import { Container, Row, } from 'react-bootstrap';


import { useTranslation } from 'react-i18next';
function CancelSubscription() {

    const { i18n, t } = useTranslation();


    useEffect(() => {
        if (!i18n.language) {
            i18n.changeLanguage('en'); // Set default language to English
        }
    }, [i18n]);


    return (
        <Container>
            <Row>
                <Container>
                    <h1> Cancel, pause or change a subscription</h1>
                    <h2>iOS Phones</h2>
                    <p>How to cancel a subscription on your iPhone, iPad or iPod touch

                        Please follow this <a href="https://support.apple.com/en-gb/118428">https://support.apple.com/en-gb/118428</a></p>


                    <h2>Android Phones</h2>
                    <p>Cancel, pause or change a subscription on Google Play ( Please make sure you have the latest version) Go to Account - My Subscription – cancel subscription</p>





                </Container>
            </Row >
        </Container >
    );
}

export default CancelSubscription;
